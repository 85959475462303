import axiosApi from '@/service/modules/axios-api'
import { SYS_MICRO_SERVICE_PREFIX } from '@/service'
// 查询标准代码分类列表
export function listCodeCategory(params) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/codeType/page', params)
}
// 获取所有标准代码分类
export function codeCategoryList() {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '', {})
}

// 查询标准代码列表
export function listCode(params) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/code/page', params)
}

// 新增标准代码分类
export function addCodeCategory(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/codeType/add', data)
}

// 查询标准代码详情
export function getCode(params) {
  return axiosApi.get(SYS_MICRO_SERVICE_PREFIX + '/code/getById', params)
}

// 修改角色
export function updateRole(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/role/edit', data)
}

// 删除标准代码分类
export function delType(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/role/remove', data)
}
// 删除标准代码
export function delCode(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/code/remove', data)
}

// 修改角色状态
export function shiftRoleStatus(data) {
  return axiosApi.post(SYS_MICRO_SERVICE_PREFIX + '/role/edit/status', data)
}
